/*
 * LicenseSure
 * https://licensesure.biz
 *
 * LicenseSure template component.
 * Renders markdown content.
 */

// Core dependencies
import { graphql } from "gatsby";
import React from "react";

// App dependencies
import AppendageEducation from "../components/appendageEducation/appendageEducation";
import AppendageState from "../components/appendageState/appendageState";
import Layout from "../components/layout";
import Markdown from "../components/markdown/markdown";

// Styles
import {
  quickLink,
  services,
  stateBreakdown as stateBreakdownStyles,
} from "../components/markdown/markdown.module.css";
import { container } from "./contentTemplate.module.css";

const classNames = require("classnames");

export default function ContentTemplate({ data, location }) {
  const post = data.markdownRemark,
    { fields, frontmatter, htmlAst } = post,
    { education, slug, stateBreakdown } = fields,
    { author, className } = frontmatter;
  const markdownClassName = classNames({
    [quickLink]: className === "quickLink",
    [services]: className === "services",
    [stateBreakdownStyles]: className === "stateBreakdown",
  });

  return (
    <Layout>
      <div className={container}>
        <Markdown className={markdownClassName}>{htmlAst}</Markdown>
        {stateBreakdown ? <AppendageState slug={slug} /> : null}
        {education ? (
          <AppendageEducation author={author} location={location} slug={slug} />
        ) : null}
      </div>
    </Layout>
  );
}

export const query = graphql`
  query ($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      htmlAst
      fields {
        education
        slug
        stateBreakdown
      }
      frontmatter {
        author
        className
      }
    }
  }
`;
