/*
 * LicenseSure
 * https://licensesure.biz
 *
 * LicenseSure article navigation component.
 */

// Core dependencies
import { Link } from "gatsby";
import React from "react";

// App dependencies
import { EducationStaticQuery } from "../../hooks/educationQuery";
import * as EducationService from "../../utils/education.service";

// Styles
import {
  arrowBack,
  arrowForward,
  backToList,
} from "./articleNavigation.module.css";
import { aligned, flex } from "../../styles/global.module.css";

const classNames = require("classnames");

export default function ArticleNavigation({ slug }) {
  const categories = EducationService.flattenEducationTopics(
    EducationStaticQuery()
  );
  const nextTopic = EducationService.getNextArticle(categories, slug);
  const nextTopicLinkText = nextTopic
    ? buildNextTopicLinkText(nextTopic.heading)
    : "";
  return (
    <div className={classNames(backToList, flex)}>
      <Link className={classNames(aligned, flex)} to="/education">
        <i className={classNames("material-icons-round", arrowBack)}>
          arrow_back_ios
        </i>
        <i className={classNames("material-icons-round", arrowForward)}>
          arrow_forward_ios
        </i>
        <span>Education Center</span>
      </Link>
      {nextTopic ? (
        <Link className={classNames(aligned, flex)} to={nextTopic.slug}>
          <i className={"material-icons-round"}>arrow_forward_ios</i>
          <span>{nextTopicLinkText}</span>
        </Link>
      ) : null}
    </div>
  );
}

function buildNextTopicLinkText(heading) {
  if (!heading) {
    return;
  }
  const maxWordCount = 7;
  const headingTokens = heading.split(" ");
  if (headingTokens.length < maxWordCount) {
    return heading;
  }
  return `${headingTokens.splice(0, maxWordCount).join(" ")}...`;
}
