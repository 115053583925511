/*
 * LicenseSure
 * https://licensesure.biz
 *
 * LicenseSure appendage component to core content template for the education pages.
 */

// Core dependencies
import React from "react";

// App dependencies
import ArticleNavigation from "../articleNavigation/articleNavigation";
import Author from "../author/author";
import MoreTopics from "../moreTopics/moreTopics";
import Socials from "../socials/socials";

// Styles
import { education } from "../socials/socials.module.css";

class AppendageEducation extends React.Component {
  render() {
    const { author, location, slug } = this.props;
    return (
      <>
        <Socials
          className={education}
          linkedin
          location={location}
          facebook
          twitter
          email
          share
        />
        <Author authorKey={author} />
        <ArticleNavigation slug={slug} />
        <MoreTopics slug={slug} />
      </>
    );
  }
}

export default AppendageEducation;
