/*
 * LicenseSure
 * https://licensesure.biz
 *
 * LicenseSure more topics component.
 */

// Core dependencies
import { Link } from "gatsby";
import React from "react";

// App dependencies
import { EducationStaticQuery } from "../../hooks/educationQuery";
import * as EducationService from "../../utils/education.service";

// Styles
import { moreTopics as moreTopicsStyles } from "./moreTopics.module.css";

export default function MoreTopics({ slug }) {
  const categories = EducationService.flattenEducationTopics(
    EducationStaticQuery()
  );
  const moreTopics = EducationService.getAnyMoreTopics(categories, slug);
  const anyMoreTopics = moreTopics && moreTopics.length;
  return anyMoreTopics ? (
    <div className={moreTopicsStyles}>
      <h2>Related Articles</h2>
      <ul>
        {moreTopics.map(({ blurb, heading, slug }) => (
          <li key={slug}>
            <h3>
              <Link to={slug}>
                <span>{heading}</span>
              </Link>
            </h3>
            <Link to={slug}>{blurb ? <p>{blurb}</p> : null}</Link>
          </li>
        ))}
      </ul>
    </div>
  ) : null;
}
