/*
 * LicenseSure
 * https://licensesure.biz
 *
 * LicenseSure author component.
 */

// Core dependencies
import React from "react";

// App dependencies
import { AuthorStaticQuery } from "../../hooks/authorQuery";
import Markdown from "../markdown/markdown";

// Styles
import { author as authorStyles } from "./author.module.css";

export default function Author({ authorKey }) {
  let author = AuthorStaticQuery(authorKey);
  if (!author) {
    author = AuthorStaticQuery("patti-harris");
  }
  const htmlAst = author.childMarkdownRemark.htmlAst;
  return htmlAst ? (
    <Markdown className={authorStyles}>{htmlAst}</Markdown>
  ) : null;
}
