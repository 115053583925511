import { useStaticQuery, graphql } from "gatsby";

export const AuthorStaticQuery = (author) => {
  const { allFile } = useStaticQuery(
    graphql`
      query AuthorStaticQuery {
        allFile(filter: { relativeDirectory: { regex: "/author/" } }) {
          edges {
            node {
              name
              childMarkdownRemark {
                htmlAst
              }
            }
          }
        }
      }
    `
  );
  return allFile.edges.map((e) => e.node).find((n) => n.name === author);
};
