/*
 * LicenseSure
 * https://licensesure.biz
 *
 * LicenseSure appendage component to core content template for state details.
 */

// Core dependencies
import { Link } from "gatsby";
import React from "react";

// App dependencies
import * as StateService from "../../utils/state.service";
import ContactUs from "../contact/contactUs";

// Styles
import { close } from "./appendageState.module.css";

export default function AppendageState({ slug }) {
  const acronym = StateService.getSlugStateAcronym(slug);
  const state = StateService.getStateName(acronym);
  const title = "More Info - Contact Us";
  const text = `Want to learn more about ${state}?`;
  return (
    <>
      <Link to={"/#map"} className={close} />
      <ContactUs text={text} state={state} title={title} />
    </>
  );
}
